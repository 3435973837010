import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { i18n, useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import dynamic from "next/dynamic"
import _isEmpty from "lodash/isEmpty"
import Head from "next/head"

import {
  FAQ_PAGE_DATA_URL,
  footerUrl,
  headerUrl,
  previewContentPrefix,
  PRIVACY_ASPATH,
  DISABLE_SROLL_PATH,
  pageNotFoundDataUrl,
  HELP_AND_FAQ,
  INSTALLATION_SERVICES_ASPATH,
  DEFAULT_SCROLL,
  BROWSE_ALL_STORES,
  BROWSE_ALL_STORES_URL,
  LOCATIONS_ASPATH,
  VANITY_REDIRECT_TYPE,
  publicWebsiteUrl,
  BUYING_GUIDE_ASPATH,
  STORE_APP_LD_JSON,
} from "@/constants"
import { aemAxiosInstance, aemPreviewAxiosInstance } from "@/constants/api"

import useIsSsr from "@/hooks/useIsSsr"
import usePageAuth from "@/hooks/usePageAuth"

import {
  getScriptsAndStyles,
  removeAndCleanLink,
  filterBackEndJson,
  getClienIP,
  getDataLayerObj,
  getOffsetTop,
  performRedirect,
  generateCanonicalUrl,
} from "@/utils/helper"

import {
  closeMenu,
  openMenu,
  selectGenericState,
  setActiveMainCategory,
  setAuthNav,
  setBrandLogoImg,
  setDatalayerPageName,
  setGbhDataLayerCustom,
  setGlobalScripts,
  setGlobalStyles,
  setPageIsIntercative,
  setRegionNav,
} from "@/store/features/genericSlice"

import AemGrid from "@/components/AemGrid"
import NextHead from "@/components/core/Head/NextHead"
import BreadCrumb from "@/components/Default/BreadCrumb"
import ContactUs from "@/components/ContactUs/v1/ContactUs"
import KeySpecifier from "@/components/KeySpecifier/v1/KeySpecifier"
import AssociateMembership from "@/components/AssociateMembership/v1/AssociateMembership"
import Literature from "@/components/Literature/v1/Literature"
import TechnicalSpecs from "@/components/TechnicalSpecs/v1/TechnicalSpecs"
import ProductAccordion from "@/components/ProductDetail/v3/ProductAccordion"
import GlobalPageHeader from "@/components/Shared/GlobalPage/GlobalPageHeader"
import GlobalPageFooter from "@/components/Shared/GlobalPage/GlobalPageFooter"
import KnowledgeArticle from "@/components/KnowledgeArticle/v1/KnowledgeArticle"
import KnowledgeArticleFiller from "@/components/KnowledgeArticle/v1/KnowledgeArticleFiller"
import BrowseAllStores from "@/components/BrowseAllStores/v1/BrowseAllStores"
import FriendsAndFamily from "@/components/FriendsAndFamily/v1/FriendsAndFamily"
import InstallDesignServices from "@/components/InstallDesignServices/v1/InstallDesignServices"
import FindStore from "@/components/FindStore/v1/FindStore"
import ProductList from "@/components/ProductList/v2/ProductList"
import PLPLoader from "@/components/ProductList/v2/PLPLoader"
import UpSellInstallServices from "@/components/Shared/UpsellInstallServices"
import styles from "@/components/ProductList/v2/ProductListView/index.module.scss"
import productTileStyles from "@/components/ProductList/v2/ProductTile/index.module.scss"
import { getAriaLabel, getCartCount } from "@/utils/cart"
import { selectCartState } from "@/store/features/cartSlice"
import {
  formatListingData,
  getListFromServer,
  getPlpParams,
} from "@/utils/product"
import { setPlpData } from "@/store/features/productListSlice"
import LeadGen from "@/components/LeadGeneration"

const StoreDataTags = dynamic(() => import("@/components/StoreDataTags"))

/**
 * renders home page
 * @return {JSX.Element}
 */
function Page({
  tmpData: data,
  headertmp: header,
  footertmp: footer,
  ip,
  globalScript,
  footerScript,
  analyticsData,
  gbhDataLayerCustom,
  fullUrl,
  urlNames,
  data404,
  datalayerPageName,
  plpData = {},
  storeData = {},
}) {
  const dispatch = useDispatch()
  const router = useRouter()
  const isSsr = useIsSsr()
  const {
    brandLogoImg,
    menu: { isOpen, mainCategoryActive, isRegionOpen, isAuthNavOpen },
  } = useSelector(selectGenericState)
  const { cartUrl } = useSelector(selectCartState)
  const [pageRedirect, setPageRedirect] = useState("")
  const [isScrollMarginRequired, setIsScrollMarginRequired] = useState(false)
  const [urlRefValue, setUrlValue] = useState(
    `#${router?.asPath?.split("#")[1] ?? ""}`
  )

  const { pageUrl = "", redirectTarget: { page: { path = "" } = {} } = {} } =
    data

  const { scroll: defaultScrollValue } = DEFAULT_SCROLL
  const { t } = useTranslation("common")
  usePageAuth()
  const cartCount = getCartCount()
  const ariaLabel = getAriaLabel(t, cartCount)

  const toggleMenu = () => {
    if (isOpen) {
      dispatch(closeMenu())
      dispatch(setAuthNav(false))
    } else {
      dispatch(openMenu())
    }
  }

  const redirect = () => {
    router.push(`${cartUrl}`)
  }

  const eventHandler = {
    store: {
      brandLogoImg: brandLogoImg,
      isOpen,
      mainCategoryActive,
      isRegionOpen,
      isAuthNavOpen,
      cartCount,
      ariaLabel,
      cartUrl,
      plp: { ...plpData, status: "succeeded" },
    },
    callbacks: {
      setBrandLogoImg: path => {
        dispatch(setBrandLogoImg(path))
      },
      closeMenu: () => {
        dispatch(closeMenu())
      },
      setMainCategory: payload => {
        dispatch(setActiveMainCategory(payload))
      },
      toggleMenu: () => {
        toggleMenu()
      },
      setRegionNav: active => {
        dispatch(setRegionNav(active))
      },
      setAuthNav: active => {
        dispatch(setAuthNav(active))
      },
      setGlobalScripts: item => {
        dispatch(setGlobalScripts(item))
      },
      setGlobalStyles: item => {
        dispatch(setGlobalStyles(item))
      },
      onClick: () => {
        redirect()
      },
    },
  }

  useEffect(() => {
    if (plpData) {
      dispatch(
        setPlpData({ ...plpData, status: "succeeded", initialLoad: true })
      )
    }
    if (gbhDataLayerCustom.pageType === "plp") {
      dispatch(setDatalayerPageName(datalayerPageName))
      dispatch(setGbhDataLayerCustom(gbhDataLayerCustom))
      return () => {
        dispatch(setDatalayerPageName(""))
        dispatch(setGbhDataLayerCustom(""))
      }
    }
  }, [datalayerPageName, gbhDataLayerCustom, plpData])

  useEffect(() => {
    if (pageUrl) {
      const containsString = DISABLE_SROLL_PATH.some(str =>
        pageUrl.includes(str)
      )
      const installServiceHash =
        (router?.asPath?.includes(INSTALLATION_SERVICES_ASPATH) &&
          router?.asPath?.includes("#")) ??
        false
      const buyingGuideHash =
        (router?.asPath?.includes(BUYING_GUIDE_ASPATH) &&
          router?.asPath?.includes("#")) ??
        false

      if (containsString) {
        window.scrollTo({
          top: 1,
          behavior: "smooth",
        })
      }

      if (installServiceHash || buyingGuideHash) {
        const element = document.querySelector(`${urlRefValue}`) ?? {}
        const offsetTop = getOffsetTop(element) - defaultScrollValue
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
          left: 0,
        })
      }
    }
  }, [pageUrl, urlRefValue])

  useEffect(() => {
    if (ip) sessionStorage.setItem("true-ip", ip)
  }, [ip])

  useEffect(() => {
    dispatch(setGbhDataLayerCustom(gbhDataLayerCustom))
    if (router?.asPath?.includes(PRIVACY_ASPATH)) {
      dispatch(setPageIsIntercative(true))
    }
    if (router?.asPath?.includes(INSTALLATION_SERVICES_ASPATH)) {
      setIsScrollMarginRequired(true)
    }

    const handleHashChange = () => {
      const hash = window.location.hash
      setUrlValue(hash)
    }
    window.addEventListener("hashchange", handleHashChange)

    return () => {
      dispatch(setGbhDataLayerCustom(""))
      window.removeEventListener("hashchange", handleHashChange)
    }
  }, [])
  useEffect(() => {
    getDataLayerObj(analyticsData)
  }, [analyticsData])

  useEffect(() => {
    if (path) {
      setPageRedirect(removeAndCleanLink(path))
    }
  }, [path])
  useEffect(() => {
    if (!isSsr && pageRedirect) {
      router.replace(pageRedirect)
    }
  }, [isSsr, pageRedirect])
  useEffect(() => {
    getScriptsAndStyles(data)
  }, [data])

  const getStoreApplicationJsonLDData = () => {
    const applicationJsonLdData = {}
    const address = {}
    const geo = {}
    const weekdays = {}
    const saturday = {}
    const sunday = {}
    applicationJsonLdData["@context"] = STORE_APP_LD_JSON.context
    applicationJsonLdData["@type"] = STORE_APP_LD_JSON.type
    address["@type"] = STORE_APP_LD_JSON.addressType
    address.streetAddress = storeData?.schema?.street
    address.addressLocality = storeData?.schema?.locality
    address.addressRegion = storeData?.schema?.region
    address.postalCode = storeData?.schema?.zipCode
    address.addressCountry = storeData?.schema?.country
    weekdays["@type"] = STORE_APP_LD_JSON.openingHourType
    weekdays.dayOfWeek = [
      STORE_APP_LD_JSON.openingDays.monday,
      STORE_APP_LD_JSON.openingDays.tuesday,
      STORE_APP_LD_JSON.openingDays.wednesday,
      STORE_APP_LD_JSON.openingDays.thursday,
      STORE_APP_LD_JSON.openingDays.friday,
    ]
    weekdays.opens = storeData?.schema?.weekdayOpenHour
    weekdays.closes = storeData?.schema?.weekdayCloseHour
    saturday["@type"] = STORE_APP_LD_JSON.openingHourType
    saturday.dayOfWeek = [STORE_APP_LD_JSON.openingDays.saturday]
    saturday.opens = storeData?.schema?.saturdayOpenHour
    saturday.closes = storeData?.schema?.saturdayCloseHour
    sunday["@type"] = STORE_APP_LD_JSON.openingHourType
    sunday.dayOfWeek = [STORE_APP_LD_JSON.openingDays.sunday]
    sunday.opens = storeData?.schema?.sundayOpenHour
    sunday.closes = storeData?.schema?.sundayCloseHour
    geo["@type"] = STORE_APP_LD_JSON.geoType
    geo.latitude = storeData?.schema?.latitude
    geo.longitude = storeData?.schema?.longitude
    applicationJsonLdData.name = storeData?.schema?.name
    applicationJsonLdData.image = storeData?.schema?.image
    applicationJsonLdData.description = storeData?.schema?.description
    applicationJsonLdData.hasMap = storeData?.schema?.hasMap
    applicationJsonLdData.url = storeData?.schema?.url
    applicationJsonLdData.telephone = storeData?.schema?.telephone
    applicationJsonLdData.priceRange = storeData?.schema?.priceRange
    applicationJsonLdData.address = { ...address }
    applicationJsonLdData.geo = { ...geo }
    applicationJsonLdData.openingHoursSpecification = [
      { ...weekdays },
      { ...saturday },
      { ...sunday },
    ]
    return JSON.stringify(applicationJsonLdData)
  }

  return (
    <>
      {data ? <NextHead {...data} fullUrl={fullUrl} /> : null}
      {data?.templateName == "store-detail-page" && (
        <Head>
          <script type="application/ld+json">
            {getStoreApplicationJsonLDData()}
          </script>
        </Head>
      )}
      {header ? (
        <GlobalPageHeader
          header={header}
          title={data.title}
          globalScripting={globalScript}
        />
      ) : null}
      {data ? (
        <AemGrid
          eventHandler={eventHandler}
          isScrollMarginRequired={isScrollMarginRequired}
          data={data}
          count={0}
          breadCrumb={(data, fullData) => (
            <BreadCrumb data={data} fullData={fullData} />
          )}
          productAccordion={(data, fullData) => (
            <ProductAccordion data={data} fullData={fullData} />
          )}
          contactUsFiller={() => <KnowledgeArticleFiller />}
          contactUs={data => <ContactUs data={data} />}
          leadGeneration={data => <LeadGen data={data} />}
          keySpecifier={data => <KeySpecifier data={data} />}
          associateMembership={data => <AssociateMembership data={data} />}
          friendsAndFamily={data => <FriendsAndFamily data={data} />}
          literature={data => <Literature data={data} />}
          technicalSpecifications={data => (
            <div id="main-content">
              <TechnicalSpecs data={data} />
            </div>
          )}
          knowledgeArticle={(data, urlNames) => (
            <KnowledgeArticle
              data={data}
              urlNames={urlNames}
              data404={data404}
            />
          )}
          knowledgeArticleFiller={() => <KnowledgeArticleFiller />}
          urlNames={urlNames}
          browseAllStores={data => <BrowseAllStores data={data} />}
          installDesignServices={data => <InstallDesignServices data={data} />}
          findStore={data => <FindStore data={data} />}
          productList={(data, eventHandler) => (
            <ProductList data={data} eventHandler={eventHandler} />
          )}
          productListFiller={() => (
            <div
              className={`${styles.productListWrapper} ${productTileStyles.productTileWrapper}`}
            >
              <PLPLoader />
            </div>
          )}
          upSellContainer={data => <UpSellInstallServices authData={data} />}
        />
      ) : null}
      {footer ? (
        <GlobalPageFooter footer={footer} footerScripting={footerScript} />
      ) : null}
      {!_isEmpty(storeData) ? <StoreDataTags authData={storeData} /> : null}
    </>
  )
}

/**
 *
 * @return {Object}
 */
export async function getServerSideProps({
  req,
  res,
  locale,
  query,
  resolvedUrl,
  params,
}) {
  await i18n?.reloadResources()
  const ip = getClienIP(req)

  const { redirectUrl = "", redirectType = "" } =
    performRedirect(req?.url, locale) ?? {}

  if (redirectUrl && redirectType != VANITY_REDIRECT_TYPE) {
    return {
      redirect: {
        destination: redirectUrl,
        permanent: true,
      },
    }
  }

  const isPreviewMode = req.cookies["aem-author-preview-mode"]
  const { queryAspath = "" } = query ?? {}

  const { url = "" } = req
  let requestUrl = url.split("?")[0] ?? ""

  if (requestUrl.indexOf(".json") > -1 && requestUrl.indexOf("_next") > -1) {
    requestUrl = decodeURIComponent(resolvedUrl).split("|").join("/")

    if (requestUrl.indexOf("SPLITVAL") > -1) {
      requestUrl = requestUrl.split("SPLITVAL")[1]
    }
  }

  const tempUrl = requestUrl?.includes(HELP_AND_FAQ)
    ? FAQ_PAGE_DATA_URL
    : requestUrl?.includes(BROWSE_ALL_STORES)
    ? BROWSE_ALL_STORES_URL
    : queryAspath?.includes(LOCATIONS_ASPATH)
    ? LOCATIONS_ASPATH
    : requestUrl
  const reqUrl = tempUrl?.includes(FAQ_PAGE_DATA_URL)
    ? FAQ_PAGE_DATA_URL
    : tempUrl?.includes(BROWSE_ALL_STORES_URL)
    ? BROWSE_ALL_STORES_URL
    : tempUrl

  const fullUrl = generateCanonicalUrl(requestUrl, locale, publicWebsiteUrl)

  let urlNames = query["urlNames"] ? query["urlNames"] : ""

  const urlFragment = requestUrl.split("/").pop() ?? ""
  if (
    !urlNames &&
    requestUrl?.includes(HELP_AND_FAQ) &&
    urlFragment !== HELP_AND_FAQ
  ) {
    urlNames = urlFragment
  }

  let data
  try {
    const response = isPreviewMode
      ? await aemPreviewAxiosInstance({
          url: `${previewContentPrefix}/${locale}${reqUrl}.model.json`,
          method: "GET",
        })
      : redirectType === VANITY_REDIRECT_TYPE
      ? await aemAxiosInstance({
          url: `${redirectUrl}.model.json`,
          method: "GET",
        })
      : await aemAxiosInstance({
          url: `/${locale}${reqUrl}.model.json`,
          method: "GET",
        })
    data = response?.data
  } catch (err) {
    return {
      notFound: true,
    }
  }

  // For header
  let header = {}
  try {
    const headerResponse = isPreviewMode
      ? await aemPreviewAxiosInstance({
          url: `${data.headerExpFrg}.model.json`,
          method: "GET",
        })
      : await aemAxiosInstance({
          url: `${data.headerExpFrg}.model.json`,
          method: "GET",
        })
    if (headerResponse?.status === 200) {
      header = headerResponse?.data ?? {}
    } else {
      console.error(
        "Non-successful header response status:",
        headerResponse.status
      )
    }
  } catch (error) {
    // Handle the error here (e.g., log it or take other actions)
    console.error("Error fetching header data:", error)

    // If the request failed, fall back to headerUrl
    try {
      const headerFallbackResponse = isPreviewMode
        ? await aemPreviewAxiosInstance({
            url: `${headerUrl}.model.json`,
            method: "GET",
          })
        : await aemAxiosInstance({
            url: `${headerUrl}.model.json`,
            method: "GET",
          })
      if (headerFallbackResponse.status === 200) {
        header = headerFallbackResponse.data
      } else {
        console.error(
          "Non-successful header response status:",
          headerFallbackResponse.status
        )
      }
    } catch (fallbackError) {
      // Handle the fallback error here if needed
      console.error(
        "Error fetching header data from fallback URL:",
        fallbackError
      )
    }
  }

  let footer = {}

  try {
    const footerResponse = isPreviewMode
      ? await aemPreviewAxiosInstance({
          url: `${data.footerExpFrg}.model.json`,
          method: "GET",
        })
      : await aemAxiosInstance({
          url: `${data.footerExpFrg}.model.json`,
          method: "GET",
        })
    if (footerResponse.status === 200) {
      footer = footerResponse.data
    } else {
      console.error(
        "Non-successful footerResponse response status:",
        footerResponse.status
      )
    }
  } catch (error) {
    // Handle the error here (e.g., log it or take other actions)
    console.error("Error fetching header data:", error)

    // If the request failed, fall back to headerUrl
    try {
      const footerFallbackResponse = isPreviewMode
        ? await aemPreviewAxiosInstance({
            url: `${footerUrl}.model.json`,
            method: "GET",
          })
        : await aemAxiosInstance({
            url: `${footerUrl}.model.json`,
            method: "GET",
          })
      if (footerFallbackResponse.status === 200) {
        header = footerFallbackResponse.data
      } else {
        console.log(
          "Non-successful footer response status:",
          footerFallbackResponse.status
        )
      }
    } catch (fallbackError) {
      // Handle the fallback error here if needed
      console.error(
        "Error fetching header data from fallback URL:",
        fallbackError
      )
    }
  }

  let plpData = {}
  if (data?.pageComponentProperties?.templateName === "plp-page") {
    const categoryKey = getPlpParams(data, "categoryKey")
    try {
      const data = await getListFromServer(categoryKey, "GST")
      plpData = await formatListingData(data, true)
    } catch (error) {
      plpData = null
    }
  }
  const { data: { pageComponentProperties: pageData404 = {} } = {} } =
    await aemAxiosInstance({
      url: pageNotFoundDataUrl,
      method: "GET",
    })
  const globalScript = data?.globalScript ? data?.globalScript : null
  const footerScript = data?.footerScript ? data?.footerScript : null
  const topHeadScript = data?.topHeadScript ? data?.topHeadScript : null
  const analyticsScriptClass = data?.analyticsScriptClass
    ? data?.analyticsScriptClass
    : null

  const storeData = data?.storeData ?? {}

  let tmpData = {}
  if (data.pageComponentProperties) {
    tmpData = data.pageComponentProperties
  } else {
    tmpData = data
  }

  const analyticsData = data?.data ? data?.data : null

  const gbhDataLayerCustom = {}
  if (data?.datalayerPageName) {
    gbhDataLayerCustom.datalayerPageName = data?.datalayerPageName
  }
  if (data?.eventPageType) {
    gbhDataLayerCustom.eventPageType = data?.eventPageType
  }
  if (data?.pageType) {
    gbhDataLayerCustom.pageType = data?.pageType
  }
  if (
    !data?.pageComponentProperties?.description &&
    data?.pageComponentProperties?.dataLayer &&
    data?.pageComponentProperties?.dataLayer[data?.pageComponentProperties?.id]
  ) {
    gbhDataLayerCustom.dataLayer =
      data?.pageComponentProperties?.dataLayer[
        data?.pageComponentProperties?.id
      ]
  }
  tmpData = filterBackEndJson(tmpData)
  if (data?.pageUrl) {
    tmpData["pageUrl"] = data?.pageUrl
  }
  const metaContent = data?.metaContent ?? ""
  const headertmp = filterBackEndJson(header)
  const footertmp = filterBackEndJson(footer)
  const data404 = filterBackEndJson(pageData404)
  const datalayerPageName = data?.datalayerPageName
  const canonicalUrl = data?.canonicalUrl ?? ""
  // Pass data to the page via props
  return {
    props: {
      ip,
      tmpData,
      headertmp,
      footertmp,
      globalScript,
      footerScript,
      topHeadScript,
      analyticsData,
      analyticsScriptClass,
      gbhDataLayerCustom,
      fullUrl,
      urlNames,
      data404,
      datalayerPageName,
      plpData,
      urlPath: requestUrl,
      storeData,
      metaContent,
      canonicalUrl,
      ...(await serverSideTranslations(locale, ["common"])),
    },
  }
}

export default Page
