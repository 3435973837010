
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/content/[[...page]]",
      function () {
        return require("private-next-pages/content/[[...page]].jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/content/[[...page]]"])
      });
    }
  